import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-12 mb-3" }
const _hoisted_3 = { class: "card pt-4 mb-xl-9" }
const _hoisted_4 = { class: "card-header border-0" }
const _hoisted_5 = { class: "card-title" }
const _hoisted_6 = { class: "fw-bolder" }
const _hoisted_7 = { class: "card-body pt-0" }
const _hoisted_8 = { class: "fw-bolder fs-2" }
const _hoisted_9 = { class: "fs-7 fw-normal text-muted" }
const _hoisted_10 = { class: "card bg-success bg-opacity-25 h-100" }
const _hoisted_11 = { class: "card-header flex-nowrap border-0 pt-9" }
const _hoisted_12 = { class: "card-title m-0" }
const _hoisted_13 = {
  href: "#",
  class: "fs-4 fw-bold text-hover-primary text-gray-900 m-0"
}
const _hoisted_14 = { class: "card-body d-flex flex-column pb-8" }
const _hoisted_15 = { class: "d-flex align-items-center flex-wrap mb-5 fs-6" }
const _hoisted_16 = { class: "text-success fw-bolder me-2" }
const _hoisted_17 = { class: "badge badge-light" }
const _hoisted_18 = { class: "d-flex align-items-center fw-bold" }
const _hoisted_19 = { class: "btn btn-success btn-sm btn-light btn-active-light-success" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("h2", _hoisted_6, _toDisplayString(_ctx.$t("pages.ideas.title")), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t("pages.ideas.desc")), 1)
          ])
        ])
      ])
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.categories, (category) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "col-4 mb-4",
        key: category.pk
      }, [
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("div", _hoisted_12, [
              _cache[0] || (_cache[0] = _createElementVNode("i", { class: "fas fa-lightbulb fs-3 me-3 text-gray-900" }, null, -1)),
              _createElementVNode("a", _hoisted_13, _toDisplayString(category.label), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("div", _hoisted_15, [
              _createElementVNode("div", _hoisted_16, [
                _createElementVNode("span", _hoisted_17, [
                  _createElementVNode("strong", null, _toDisplayString(category.ideas.length) + " idée(s)", 1)
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_18, [
              _createVNode(_component_router_link, {
                to: '/ideas-detail/' + category.pk + '/'
              }, {
                default: _withCtx(() => [
                  _createElementVNode("a", _hoisted_19, _toDisplayString(_ctx.$t("pages.ideas.accessIdeas")), 1)
                ]),
                _: 2
              }, 1032, ["to"])
            ])
          ])
        ])
      ]))
    }), 128))
  ]))
}